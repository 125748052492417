import Auth from '@/components/Auth';
import { PaymentItem } from '@/components/Plans/PaymentItem';
import Payment from '@/components/ui/Payment';
import { ROUTES } from '@/data';
import { useTranslations } from '@/hooks';
const PlanActionDefault = ({
  title,
  slug,
  icon,
  color,
  isActive,
  displayMonthly,
  disabled,
  disabledDowngrade,
  defaultOpen,
  user,
  planName,
  price,
  save
}) => {
  const t = useTranslations();
  const getPaymentLabel = () => {
    if (user && user?.plan?.name === 'artist') {
      return t('common.actions.upgrade');
    }
    return void 0;
  };
  return <Auth login loginLabel={t('navigation.login.required')} redirect={ROUTES.PRICING} data-sentry-element="Auth" data-sentry-component="PlanActionDefault" data-sentry-source-file="PlanActionDefault.jsx">
      <Payment disabled={disabled} defaultOpen={defaultOpen} mode="subscription" disabledDowngrade={disabledDowngrade} isActive={isActive} plan={slug} planName={planName} color={color} title={title} price={price} save={save} monthly={displayMonthly} label={getPaymentLabel()} data-sentry-element="Payment" data-sentry-source-file="PlanActionDefault.jsx">
        <PaymentItem title={title} subtitle={t('navigation.account_plan')} icon={icon} data-sentry-element="PaymentItem" data-sentry-source-file="PlanActionDefault.jsx" />
      </Payment>
    </Auth>;
};
export default PlanActionDefault;