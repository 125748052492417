import { Countdown } from '@/components/ui/Countdown';
import { CURRENCY_SYMBOL } from '@/data';
import { useTranslations } from '@/hooks';
const PlanPriceDefault = ({
  price,
  save,
  salePrice,
  countdown,
  duration,
  displayMonthly
}) => {
  const t = useTranslations();
  const monthLabel = t('common.time.periods.monthly');
  if (!displayMonthly) {
    return <div className="w-full">
        {!!salePrice && <span className="plan-sale">
            {CURRENCY_SYMBOL}
            {price}/{duration}
          </span>}

        {salePrice > 0 && <h3>
            {CURRENCY_SYMBOL}
            {salePrice || price}
            <span className="ml-1">/{duration}</span>
          </h3>}

        {salePrice > 0 && price > 0 && <span className="mt-2 text-lg tag tag-green">
            {t('payment.common.you_save')} {save} {CURRENCY_SYMBOL}
          </span>}

        {countdown && <Countdown className="mt-4" initialCount={countdown} />}

        {salePrice === 0 && <>
            <span className="plan-sale">
              {CURRENCY_SYMBOL}
              {price}
              {' / '}
              {duration}
            </span>
            <h3 className="plan-free">Free</h3>
          </>}
      </div>;
  }
  return <div className="w-full" data-sentry-component="PlanPriceDefault" data-sentry-source-file="PlanPriceDefault.jsx">
      {!!salePrice && <span className="plan-sale">
          {CURRENCY_SYMBOL}
          {price}
          {' / '}
          {monthLabel}
        </span>}

      {salePrice > 0 && <h3 className="flex items-center">
          {CURRENCY_SYMBOL}
          {salePrice}
          {' / '}
          {monthLabel}
        </h3>}

      <span className="block mt-2 plan-billing">
        {t('payment.common.billing.yearly')}
      </span>

      {salePrice > 0 && price > 0 && <span className="mt-2 text-lg tag tag-green">
          {t('payment.common.you_save')} {save} {'%'}
        </span>}

      {countdown && <Countdown className="mt-4" initialCount={countdown} />}

      {salePrice === 0 && <>
          <span className="plan-sale">
            {CURRENCY_SYMBOL}
            {price}
            {' / '}
            {monthLabel}
          </span>
          <h3 className="plan-free">Free</h3>
        </>}
    </div>;
};
export default PlanPriceDefault;