'use client';

import { useEffect, useRef, useState } from 'react';
import { cn } from '@/utils';
const STATUS = {
  STARTED: 'Started',
  STOPPED: 'Stopped'
};
function useInterval(callback, delay) {
  const savedCallback = useRef();
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
const twoDigits = num => String(num).padStart(2, '0');
export default function Countdown({
  initialCount = 60 * 60,
  limitCount = 30 * 60,
  className
}) {
  const [secondsRemaining, setSecondsRemaining] = useState(initialCount);
  const [status, setStatus] = useState(STATUS.STOPPED);
  const secondsToDisplay = secondsRemaining % 60;
  const minutesRemaining = (secondsRemaining - secondsToDisplay) / 60;
  const minutesToDisplay = minutesRemaining % 60;
  const hoursToDisplay = (minutesRemaining - minutesToDisplay) / 60;
  const handleStart = () => {
    setStatus(STATUS.STARTED);
  };
  useInterval(() => {
    if (secondsRemaining > 0) {
      setSecondsRemaining(secondsRemaining - 1);
    } else {
      setStatus(STATUS.STOPPED);
    }
  }, status === STATUS.STARTED ? 1000 : null);
  useEffect(() => {
    handleStart();
  }, []);
  const classes = cn({
    countdown: true,
    'countdown-limit': secondsRemaining < limitCount,
    [className]: className
  });
  if (secondsRemaining <= 0) return null;
  return <div className={classes} data-sentry-component="Countdown" data-sentry-source-file="Countdown.jsx">
      <div className="countdown-item">
        <p>
          {twoDigits(hoursToDisplay)}
          <span>{t('common.time.countdown.hh')}</span>
        </p>
      </div>
      <div className="countdown-item">
        <p>
          {twoDigits(minutesToDisplay)}
          <span>{t('common.time.countdown.mm')}</span>
        </p>
      </div>
      <div className="countdown-item">
        <p>
          {twoDigits(secondsToDisplay)}
          <span>{t('common.time.countdown.ss')}</span>
        </p>
      </div>
    </div>;
}