import { CURRENCY_SYMBOL } from '@/data';
import { useTranslations } from '@/hooks';
const PlanPriceVip = ({
  price
}) => {
  const t = useTranslations();
  return <div className="flex flex-col" data-sentry-component="PlanPriceVip" data-sentry-source-file="PlanPriceVip.jsx">
      <p className="mb-2 font-light opacity-75">
        {t('plans.card.common.start_from')}
      </p>
      <h3>
        {t('common.labels.from')} {CURRENCY_SYMBOL}
        {price}
        {' / '}
        {t('field.search_select.track.label')}
      </h3>
    </div>;
};
export default PlanPriceVip;