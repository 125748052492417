import Icon from '@/components/ui/Icon';
import { useLocale, useTranslations } from '@/hooks';
import { cn, formatCurrency } from '@/utils';
export const PaymentItem = ({
  icon,
  color,
  title,
  subtitle,
  salePrice,
  price,
  className
}) => {
  const t = useTranslations();
  const {
    locale
  } = useLocale();
  const classes = cn({
    'payment-item': true,
    'flex items-center justify-between': true,
    [className]: className
  });
  return <div className={classes} data-sentry-component="PaymentItem" data-sentry-source-file="PaymentItem.jsx">
      <div className="flex items-center gap-4">
        {icon && <Icon name={icon} className={`icon-${color ?? 'primary'}`} />}

        <div className="flex flex-col">
          {subtitle && <span className="block mb-1 text-sm font-light tracking-widest uppercase opacity-70">
              {subtitle}
            </span>}
          {title && <h4 className="mb-0">{title}</h4>}
        </div>
      </div>

      {(salePrice || price) && <div className="flex flex-col items-end">
          {price && <span className={cn({
        'text-base font-light strike-through opacity-70': salePrice,
        'text-xl font-bold text-green': !salePrice
      })}>
              {formatCurrency(price, locale)}
            </span>}

          {salePrice && <span className="text-xl font-bold text-green">
              {formatCurrency(salePrice, locale)}
            </span>}

          <span className="block mt-1 text-xs opacity-70">
            {t('payment.common.vat', {
          vat: 'excluded'
        })}
          </span>
        </div>}
    </div>;
};
export default PaymentItem;