'use client';

import { useState } from 'react';
import { PlanActionAnalysis, PlanActionDefault, PlanActionVip } from './actions';
import PlanPrice from './PlanPrice';
import Badge from '@/components/ui/Badge';
import Emoji from '@/components/ui/Emoji';
import Icon from '@/components/ui/Icon';
import { STRIPE_PRODUCTS } from '@/data';
import { useSession, useTranslations } from '@/hooks';
import { cn } from '@/utils';
const PlanCard = props => {
  const {
    title,
    slug,
    icon,
    color,
    features = [],
    caption,
    price,
    salePrice,
    priceMonthly,
    salePriceMonthly,
    save,
    layout,
    disabled,
    displayMonthly,
    displayPrice,
    disabledDowngrade,
    isActive,
    mostPopular,
    countdown,
    socialProof,
    cta,
    defaultOpen = false,
    ...rest
  } = props;
  const [expandFeatures, setExpandFeatures] = useState(false);
  const displayedFeatures = expandFeatures ? features : features.slice(0, 3);
  const {
    data: session
  } = useSession();
  const {
    user
  } = session || {};
  const t = useTranslations();
  const classes = cn({
    plan: true,
    [`plan-${color}`]: color,
    'plan-active ': isActive,
    'plan-most-popular': mostPopular
  });
  return <div className={classes} data-sentry-component="PlanCard" data-sentry-source-file="PlanCard.jsx">
      {socialProof && <Badge emoji={<Emoji symbol="🏆" className="mr-2 text-base" label="Trophy" />} className="mb-3 whitespace-normal" variant={`${color}-light`} label={socialProof} color={color} />}

      <div className="plan-header">
        <h2 className={`text-${color}`}>{title}</h2>
        {typeof icon === 'string' ? <Icon size="lg" className="shrink-0" name={icon} /> : icon}
      </div>

      {caption && <span className="plan-caption">{caption}</span>}

      <PlanPrice countdown={countdown} price={priceMonthly} salePrice={salePriceMonthly} layout={layout} displayPrice={displayPrice} save={save} {...rest} data-sentry-element="PlanPrice" data-sentry-source-file="PlanCard.jsx" />

      {features?.length > 0 && <ul className="plan-features">
          {displayedFeatures.map((feature, index) => {
        return <li key={index} className="flex items-start gap-2">
                <Icon name="Check" strokeWidth={3} className={cn('w-4 h-4 text-primary shrink-0 mt-[0.16rem]', {
            [`text-${color}`]: color
          })} />
                <span dangerouslySetInnerHTML={{
            __html: feature
          }} />
              </li>;
      })}

          {features?.length > 3 && <a className={`font-bold text-${color}`} onClick={() => setExpandFeatures(!expandFeatures)}>
              {!expandFeatures ? <span className="flex items-center">
                  <Icon name="ChevronDown" className="mr-2" />
                  {t('common.labels.read_more')}
                </span> : <span className="flex items-center">
                  <Icon name="ChevronUp" className="mr-2" />
                  {t('common.labels.read_less')}
                </span>}
            </a>}
        </ul>}

      <div className="plan-action">
        {cta && cta}

        {layout === 'analysis' && <PlanActionAnalysis title={title} icon={icon} color={color} disabled={disabled} price={price} user={user} planId={STRIPE_PRODUCTS.ANALYSIS} planName={slug} {...rest} />}

        {layout === 'default' && <PlanActionDefault title={title} slug={slug} planName={slug} icon={icon} color={color} isActive={isActive} displayMonthly={displayMonthly} disabled={disabled} disabledDowngrade={disabledDowngrade} defaultOpen={defaultOpen} user={user} price={salePrice} save={save} {...rest} />}

        {layout === 'vip' && <PlanActionVip color={color} disabled={disabled} />}
      </div>
    </div>;
};
export default PlanCard;