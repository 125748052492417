'use client';

import { nanoid } from 'nanoid';
import { usePathname } from 'next/navigation';
import React, { useEffect, useState } from 'react';
import { matchfyApi } from '@/api';
import { AuthButton } from '@/components/Auth';
import Button from '@/components/ui/Button';
import { PaymentDialog } from '@/components/ui/Payment';
import { useSession, useTranslations } from '@/hooks';
import { EVENTS, trackEvent } from '@/lib';
import { cn } from '@/utils';
const Payment = React.memo(function Payment({
  extra,
  autoApply,
  color = 'primary',
  contract,
  price,
  icon,
  save,
  plan,
  title,
  payload,
  creditsDiscount,
  disabledLabel,
  defaultOpen,
  disabledDowngrade,
  disabled,
  disableCredits,
  disableAffiliate,
  disableCoupon,
  isActive,
  label,
  maxCredits,
  monthly,
  mode,
  planName,
  onBeforePayment,
  children,
  className
}) {
  const {
    data: session
  } = useSession();
  const {
    user
  } = session || {};
  const [open, setOpen] = useState(defaultOpen);
  const openDialog = async () => {
    if (onBeforePayment) {
      await onBeforePayment();
    }
    matchfyApi.createCart({
      plan: planName
    });
    setOpen(true);
    trackEvent(EVENTS.INITIATE_CHECKOUT, {
      slug: plan,
      title,
      price
    }, nanoid());
  };
  const closeDialog = () => {
    setOpen(false);
  };
  const t = useTranslations();
  const getStatus = () => {
    let btnLabel = t('payment.common.actions.purchase');
    let btnIcon = icon;
    if (isActive) {
      btnLabel = t('plans.card.common.button.active');
      btnIcon = 'Check';
    } else if (disabledDowngrade) {
      btnLabel = t('plans.card.common.button.better_plan');
      btnIcon = 'X';
    } else if (label) {
      return {
        label,
        icon: btnIcon
      };
    } else if (price && save) {
      btnLabel = `${t('common.actions.upgrade')}`;
    } else if (price) {
      btnLabel = `${t('common.actions.upgrade')}`;
    } else if (disabled) {
      btnLabel = disabledLabel;
    } else if (!session) {
      btnLabel = t('navigation.login.required');
    }
    return {
      label: btnLabel,
      icon: btnIcon
    };
  };
  const isButtonDisabled = disabledLabel || isActive || disabledDowngrade;
  const pathname = usePathname();
  useEffect(() => {
    if (defaultOpen) {
      openDialog();
    }
  }, [defaultOpen]);
  const classes = cn({
    'payment-wrapper': true,
    [className]: className
  });
  return <div className={classes}>
        {!session && <AuthButton className="w-full" redirect={pathname} label={getStatus().label} variant={color} />}

        {session && <Button className="w-full" variant={color} onClick={openDialog} disabled={isButtonDisabled} icon={getStatus().icon}>
            {getStatus().label}
          </Button>}

        <PaymentDialog extra={extra} autoApply={autoApply} mode={mode} contract={contract} price={price} monthly={monthly} plan={plan} open={open} planName={planName} disable={disableCredits} disableCredits={disableCredits} disableAffiliate={disableAffiliate} disableCoupon={disableCoupon} payload={payload} userProfile={user} onClose={closeDialog} creditsDiscount={creditsDiscount} maxCredits={maxCredits}>
          {children}
        </PaymentDialog>
      </div>;
}, (prevProps, nextProps) => {
  return prevProps.price === nextProps.price && prevProps.plan === nextProps.plan && prevProps.defaultOpen === nextProps.defaultOpen;
});
export default Payment;