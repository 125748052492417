import React, { useState, useEffect, useRef } from 'react';
import { useTranslations } from '@/hooks';
import { cn } from '@/utils';
const STATUS = {
  STARTED: 'Started',
  STOPPED: 'Stopped'
};
function useInterval(callback, delay) {
  const savedCallback = useRef();
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
const twoDigits = num => String(num).padStart(2, '0');
export default function CountdownDate({
  endDate,
  inline,
  limitCount,
  className
}) {
  const t = useTranslations();
  const calculateTimeRemaining = () => {
    const now = new Date().getTime();
    const end = new Date(endDate).getTime();
    const total = Math.max(0, Math.floor((end - now) / 1000)); // Convert ms to seconds
    return total;
  };
  const [secondsRemaining, setSecondsRemaining] = useState(calculateTimeRemaining);
  const [status, setStatus] = useState(STATUS.STOPPED);
  const secondsToDisplay = secondsRemaining % 60;
  const minutesRemaining = (secondsRemaining - secondsToDisplay) / 60;
  const minutesToDisplay = minutesRemaining % 60;
  const hoursRemaining = (minutesRemaining - minutesToDisplay) / 60;
  const hoursToDisplay = hoursRemaining % 24;
  const daysToDisplay = Math.floor(hoursRemaining / 24);
  const handleStart = () => {
    setStatus(STATUS.STARTED);
  };
  useInterval(() => {
    const newSecondsRemaining = calculateTimeRemaining();
    if (newSecondsRemaining > 0) {
      setSecondsRemaining(newSecondsRemaining);
    } else {
      setStatus(STATUS.STOPPED);
    }
  }, status === STATUS.STARTED ? 1000 : null);
  useEffect(() => {
    handleStart();
  }, []);
  const classes = cn({
    countdown: true,
    'countdown-inline': inline,
    'countdown-limit': secondsRemaining < limitCount,
    [className]: className
  });
  if (secondsRemaining <= 0) return null;
  return <div className={classes} data-sentry-component="CountdownDate" data-sentry-source-file="CountdownDate.jsx">
      {daysToDisplay > 0 && <div className="countdown-item">
          <p>
            {twoDigits(daysToDisplay)}
            <span>{t('common.time.countdown.dd')}</span>
          </p>
        </div>}

      <div className="countdown-item">
        <p>
          {twoDigits(hoursToDisplay)}
          <span>{t('common.time.countdown.hh')}</span>
        </p>
      </div>

      <div className="countdown-item">
        <p>
          {twoDigits(minutesToDisplay)}
          <span>{t('common.time.countdown.mm')}</span>
        </p>
      </div>

      <div className="countdown-item">
        <p>
          {twoDigits(secondsToDisplay)}
          <span>{t('common.time.countdown.ss')}</span>
        </p>
      </div>
    </div>;
}
CountdownDate.defaultProps = {
  endDate: new Date(Date.now() + 60 * 60 * 1000),
  // Default to 1 hour from now
  limitCount: 30 * 60
};