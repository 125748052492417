'use client';

import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import { matchfyApi } from '@/api';
import { PlanCard } from '@/components/Plans';
import Badge from '@/components/ui/Badge';
import SectionTitle from '@/components/ui/SectionTitle';
import Spinner from '@/components/ui/Spinner';
import { PLANS, ROUTES_DATA } from '@/data';
import { useSession, useTranslations, useLocale } from '@/hooks';
import { cn } from '@/utils';
const Plans = ({
  className,
  showTitle = false,
  titleTag = 'h1'
}) => {
  const {
    data: session
  } = useSession();
  const user = session?.user;
  const plan = user?.plan;
  const {
    locale
  } = useLocale();
  const searchParams = useSearchParams();
  const t = useTranslations();
  const {
    data,
    isLoading
  } = useQuery({
    queryKey: ['plans'],
    queryFn: () => matchfyApi.getPlans(locale)
  });
  const [defaultOpen, setDefaultOpen] = useState(data?.find(plan => plan.defaultOpen)?.slug || null);
  useEffect(() => {
    const planParam = searchParams.get('plan');
    if (planParam) {
      setDefaultOpen(planParam);
    }
  }, [searchParams]);
  const disablePlanDowngrade = planName => {
    const plans = Object.values(PLANS);
    if (typeof plan === 'undefined') return false;
    return plans.indexOf(planName) <= plans.indexOf(plan.name);
  };
  const classes = cn({
    [className]: className
  });
  return <div className={classes} data-sentry-component="Plans" data-sentry-source-file="Plans.jsx">
      {showTitle && <SectionTitle tag={titleTag} title={t('plans.header.title')} subtitle={t('plans.page.subtitle')} badge={<Badge variant="primary-light" icon={ROUTES_DATA.PRICING.icon} label={t('plans.page.title')} />} className="mb-8" center />}

      {isLoading && <Spinner />}

      {!isLoading && <div className="plans-grid">
          {data && data.map(item => <PlanCard key={item.order} title={item.name} slug={item.slug} defaultOpen={defaultOpen === item.slug} icon={item.icon} color={item.colorClass} mostPopular={item.mostPopular} features={item.features} caption={item.caption} save={item.save} price={item.price} salePrice={item.salePrice} priceMonthly={item.priceMonthly} salePriceMonthly={item.salePriceMonthly} isActive={plan?.name?.toLowerCase() === item.slug} layout={item.slug === PLANS.VIP ? 'vip' : 'default'} disabled={!session} displayMonthly displayPrice disabledDowngrade={disablePlanDowngrade(item.slug)} socialProof={item.chooseFrom > 0 ? t('plans.card.types.artist.social_proof', {
        plans: item.chooseFrom
      }) : null} />)}
        </div>}
    </div>;
};
export default Plans;