import Badge from '@/components/ui/Badge';
import Spinner from '@/components/ui/Spinner';
import { CURRENCY_SYMBOL } from '@/data';
import { useSession, useTranslations } from '@/hooks';
const PlanPriceAnalysis = ({
  price,
  availableAnalysis,
  isLoadingCount
}) => {
  const t = useTranslations();
  const {
    data: session
  } = useSession();
  if (!price) return null;
  if (!session || availableAnalysis <= 0) {
    return <h4 className="m-0">
        {t('analysis.card.only_for')} {CURRENCY_SYMBOL}
        {price}
      </h4>;
  }
  return <Badge size="lg" variant="primary-light" className="capitalize" label={t('analysis.card.remaining_badge')} value={isLoadingCount ? <Spinner inline /> : availableAnalysis} data-sentry-element="Badge" data-sentry-component="PlanPriceAnalysis" data-sentry-source-file="PlanPriceAnalysis.jsx" />;
};
export default PlanPriceAnalysis;