import PaymentGateway from './PaymentGateway';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/Dialog';
import { useTranslations } from '@/hooks';
export default function PaymentDialog({
  open,
  onClose,
  children,
  ...props
}) {
  const t = useTranslations();
  return <Dialog open={open} onOpenChange={onClose} data-sentry-element="Dialog" data-sentry-component="PaymentDialog" data-sentry-source-file="PaymentDialog.jsx">
      <DialogContent disableOutsideClick={true} data-sentry-element="DialogContent" data-sentry-source-file="PaymentDialog.jsx">
        <DialogHeader data-sentry-element="DialogHeader" data-sentry-source-file="PaymentDialog.jsx">
          <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="PaymentDialog.jsx">{t('payment.title')}</DialogTitle>
        </DialogHeader>

        <div className="payment-dialog">
          {children && children}
          <PaymentGateway {...props} data-sentry-element="PaymentGateway" data-sentry-source-file="PaymentDialog.jsx" />
        </div>
      </DialogContent>
    </Dialog>;
}