'use client';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { matchfyApi, spotifyApi } from '@/api';
import { SearchSelect } from '@/components/Search';
import Alert from '@/components/ui/Alert';
import Button from '@/components/ui/Button';
import Card from '@/components/ui/Card';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/Dialog";
import { Empty } from '@/components/ui/Empty';
import Spinner from '@/components/ui/Spinner';
import { QUERIES } from '@/data/queries';
import { useSession, useTranslations } from '@/hooks';
const analysisDownloadPopupInitialState = {
  visible: false,
  artistId: '',
  orderData: null,
  triggerApi: false
};
const AnalysisCalculator = ({
  open,
  availableAnalysis,
  onSuccessCallback
}) => {
  const {
    data: session
  } = useSession();
  const {
    user
  } = session || {};
  const t = useTranslations();
  const queryClient = useQueryClient();
  const [analysisState, setAnalysisState] = useState(analysisDownloadPopupInitialState);
  const [state, setState] = useState({
    isExistsArtist: false,
    isExistsTrack: false,
    artist: {},
    artistIsBlocked: false,
    trackId: '',
    artistId: '',
    track: {},
    artistError: null,
    trackError: null,
    price: null,
    discountPrice: null,
    discount: '',
    discountError: null
  });
  const patch = next => setState(current => ({
    ...current,
    ...next
  }));
  const {
    data,
    isLoading,
    isSuccess,
    isError
  } = useQuery({
    queryKey: [QUERIES.ANALYSIS.GET, state.artistId],
    queryFn: () => matchfyApi.getArtistAnalysis(state?.artist),
    enabled: analysisState.triggerApi
  }, queryClient);
  const getArtist = async artistId => {
    if (!artistId) return;
    try {
      const artist = await spotifyApi.getArtist(artistId);
      const {
        blocked
      } = await matchfyApi.checkIfArtistIsBlocked(artist.id);
      patch({
        isExistsArtist: true,
        artist,
        artistError: undefined,
        artistIsBlocked: blocked
      });
    } catch (error) {
      patch({
        isExistsArtist: false,
        artistError: error.message,
        artistIsBlocked: false
      });
    }
  };
  const trackUrlHandler = async trackId => {
    if (!trackId) return true;
    try {
      const track = await spotifyApi.getTrack(trackId);
      patch({
        isExistsTrack: true,
        track,
        trackError: undefined
      });
      const {
        artists
      } = track;
      if (artists.length) {
        await getArtist(artists[0].id);
      }
    } catch (error) {
      patch({
        isExistsTrack: false,
        trackError: error.message
      });
    }
  };
  useEffect(() => {
    trackUrlHandler(state.trackId);
  }, [state.trackId]);
  useEffect(() => {
    getArtist(state.artistId);
  }, [state.artistId]);
  const handleTrackIdChanges = useCallback(value => {
    const id = value;
    if (!id) {
      patch({
        isExistsTrack: false,
        isExistsArtist: false,
        trackId: '',
        artistId: '',
        artistIsBlocked: false,
        price: null,
        discountPrice: null
      });
      return;
    }
    patch({
      price: null,
      discountPrice: null,
      artistId: id,
      trackError: undefined,
      artistError: undefined
    });
  }, []);
  const handleDiscountChanges = ({
    target
  }) => {
    patch({
      price: null,
      discountPrice: null,
      genres: null,
      discount: target.value
    });
  };
  const resetForm = () => {
    handleTrackIdChanges('');
    handleDiscountChanges({
      target: {
        value: ''
      }
    });
    queryClient.removeQueries({
      queryKey: [QUERIES.ANALYSIS.GET, state.artistId],
      exact: true
    });
    queryClient.resetQueries({
      queryKey: [QUERIES.ANALYSIS.GET, state.artistId],
      exact: true
    });
    setAnalysisState(analysisDownloadPopupInitialState);
    onSuccessCallback();
  };
  const analysisPaymentSuccess = data => {
    setAnalysisState({
      visible: true,
      artistId: state.artistId,
      orderData: data,
      triggerApi: true
    });
  };
  const onCloseHandler = () => {
    resetForm();
  };
  return <Dialog open={open} onOpenChange={open => !open && onCloseHandler()} data-sentry-element="Dialog" data-sentry-component="AnalysisCalculator" data-sentry-source-file="AnalysisCalculator.jsx">
      <DialogContent size="sm" className="overflow-visible" data-sentry-element="DialogContent" data-sentry-source-file="AnalysisCalculator.jsx">
        <DialogHeader data-sentry-element="DialogHeader" data-sentry-source-file="AnalysisCalculator.jsx">
          <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="AnalysisCalculator.jsx">
            {t('analysis.card.title')}
          </DialogTitle>
        </DialogHeader>

        <div className="flex flex-col">
          <div className="flex flex-col gap-4 md:flex-row">
            <SearchSelect className="w-full" entity="artist" isDisabled={analysisState.triggerApi} onChange={handleTrackIdChanges} data-sentry-element="SearchSelect" data-sentry-source-file="AnalysisCalculator.jsx" />
          </div>

          {state.artistIsBlocked && state.isExistsArtist && <Alert variant="danger" className="mt-4" icon="TriangleAlert" label={t('feedback.error.artist.blocked')} />}

          {state.isExistsArtist && !analysisState.triggerApi && !(isSuccess || isError) && <div className="mt-6">
                <Card className="card-info md:col-span-3" title={t('analysis.card.title')} footer={<>
                      <Button className="px-8 mt-8 text-center button-wide" disabled={state.artistIsBlocked} onClick={() => analysisPaymentSuccess(state.artistId)}>
                        {t('common.actions.continue')}
                      </Button>
                    </>}>
                  <p className="text-lg font-semibold text-primary">
                    {t('analysis.card.remaining', {
                count: availableAnalysis
              }).toLowerCase()}
                  </p>
                </Card>
              </div>}
        </div>

        {analysisState.triggerApi && !data && isLoading && <Empty className="justify-center mt-4 mb-0 text-center" emoji={<Spinner className="my-2" />} message={t('feedback.loading.analysis')} />}

        {!isLoading && isSuccess && <>
            <Alert variant="success" className="mt-4" icon="CircleCheck" label={t('feedback.success.platform.analysis')} />
            <Button className="px-8 mt-3 text-center button-wide" icon="X" label={t('common.actions.close')} onClick={() => resetForm()} />
          </>}

        {!isLoading && isError && <Alert variant="danger" className="mt-4" icon="TriangleAlert" label={t('feedback.error.analysis')} />}
      </DialogContent>
    </Dialog>;
};
export default AnalysisCalculator;